<template>
  <div class="container">
    <div v-if="loading" element-loading-text="Loading..."
         element-loading-spinner="el-icon-loading"
         v-loading="loading" class="el-loading-mask" id="loading">
    </div>
    <div class="row justify-content-center">
<!--      <div class="col-md-5 ml-auto">-->
<!--        <div class="info-area info-horizontal mt-5">-->
<!--          <div class="icon icon-success">-->
<!--            <i class="tim-icons icon-controller"></i>-->
<!--          </div>-->
<!--          <div class="description">-->
<!--            <h3 class="info-title">Track your experiences </h3>-->
<!--            <p class="description">-->
<!--              Leaderboards and game stats all in one place-->
<!--            </p>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="info-area info-horizontal">-->
<!--          <div class="icon icon-warning">-->
<!--            <i class="tim-icons icon-coins"></i>-->
<!--          </div>-->
<!--          <div class="description">-->
<!--            <h3 class="info-title">Earn quest points</h3>-->
<!--            <p class="description">-->
<!--              Level up as you play and unlock cool stuff!-->
<!--            </p>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="info-area info-horizontal">-->
<!--          <div class="icon icon-info">-->
<!--            <i class="tim-icons icon-credit-card"></i>-->
<!--          </div>-->
<!--          <div class="description">-->
<!--            <h3 class="info-title">Easy check-in</h3>-->
<!--            <p class="description">-->
<!--              Booking and check in process simplified!-->
<!--            </p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <div class="col-md-7">
        <form  @submit.prevent="register">
          <card class="card-register card-white">
            <!--<template>-->
            <!--<img class="card-img" src="img/pandas/search.png" width="10"/>-->
            <h4 class="text-dark">Register</h4>
            <!--</template>-->
            <base-alert v-show="success" type="success" >Congratulations! Your account has been created. Go to your email to confirm your account.</base-alert>

            <base-input
              v-validate="'required'"
              :error="getError('Full Name')"
              v-model="model.fullName"
              name="Full Name"
              placeholder="Full Name"
              addon-left-icon="tim-icons icon-single-02"
            >
            </base-input>

            <base-input
              v-validate="'required|email'"
              :error="getError('email')"
              v-model="model.email"
              name="email"
              placeholder="Email"
              addon-left-icon="tim-icons icon-email-85"
            >
            </base-input>

            <base-input
              v-validate="'required|min:5'"
              :error="getError('password')"
              v-model="model.password"
              name="password"
              type="password"
              placeholder="Password"
              addon-left-icon="tim-icons icon-lock-circle"
            >
            </base-input>
            <p class="text-danger ml-1 small" v-show="error">{{error}}</p>


            <base-checkbox class="text-left">
              I agree to the <a href="#something">terms and conditions</a>.
            </base-checkbox>

            <base-button native-type="submit" slot="footer" type="primary" round block size="lg">
              Create an account
            </base-button>
          </card>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import { BaseCheckbox } from 'src/components';
  import { Auth } from 'aws-amplify'
  import { AmplifyEventBus } from 'aws-amplify-vue'
  import 'src/assets/css/loader.css'
  import BaseAlert from "@/components/BaseAlert";

  export default {
    components: {
      BaseAlert,
      BaseCheckbox
    },
    data() {
      return {
        loading: false,
        success: false,
        error: '',
        model: {
          email: '',
          fullName: '',
          password: ''
        }
      };
    },
    methods: {
      getError(fieldName) {
        return this.errors.first(fieldName);
      },
      async register() {
        let isValidForm = await this.$validator.validateAll();
        if (isValidForm) {
          // TIP use this.model to send it to api and perform register call
          try {
            this.loading = true
            const user = await Auth.signUp({username: this.model.email, password: this.model.password, attributes: {name: this.model.fullName}})
            this.success = true
            this.error = ""
            this.loading = false
          } catch (err) {
            this.loading = false
            this.error = err.message
            this.success = false
          }
        }
      }
    }
  };
</script>
<style></style>
