<template>
  <div class="container">
    <div v-if="loading" element-loading-text="Loading..."
         element-loading-spinner="el-icon-loading"
         v-loading="loading" class="el-loading-mask" id="loading">
    </div>
    <div class="col-lg-5 col-md-6 ml-auto mr-auto">
      <div v-if="on_password_reset">
        <card class="card-login card-white">
          <div class="row text-center justify-content-center">
            <div class="col-lg-6">
              <img class="mb-4" src="img/pandas/search.png" alt="" />
            </div>
          </div>
          <div>
            <base-alert v-if="reset_message.length > 0" type="success">
              {{reset_message}}
            </base-alert>
            <base-input
              v-model="email_reset"
              placeholder="Email"
              addon-left-icon="tim-icons icon-email-85"
            >
            </base-input>
            <base-input v-if="awaiting_code"
              v-model="code"
              placeholder="Code"
              addon-left-icon="tim-icons icon-key-25"
            >
            </base-input>
            <base-input v-if="awaiting_code"
              v-model="new_password"
              placeholder="New Password"
              type="password"
              addon-left-icon="tim-icons icon-lock-circle"
            >
            </base-input>
          </div>

          <div slot="footer">
            <base-button v-on:click="reset()" v-if="awaiting_code" type="primary" class="mb-3" size="lg" block>
              Reset Password
            </base-button>
            <base-button v-on:click="send_code()" v-if="!awaiting_code" native-type="submit" type="primary" class="mb-3" size="lg" block>
              Send Code
            </base-button>
            <div class="pull-left">
              <h6>
                <a class="link footer-link text-primary" href="#" v-on:click="to_login()">
                  Back to Login
                </a>
              </h6>
            </div>

            <div class="pull-right">
              <h6>
                <a class="link footer-link text-primary" href="#" v-on:click="send_code()">
                  Resend Code
                </a>
              </h6>
            </div>
          </div>
        </card>
      </div>
      <div v-if="on_password_create">
        <card class="card-login card-white">
          <div class="row text-center justify-content-center">
            <div class="col-lg-6">
              <img class="mb-4" src="img/pandas/search.png" alt="" />
            </div>
          </div>
          <div>
            <base-alert v-if="reset_message.length <= 0" type="success">
              Please create a new password.
            </base-alert>
            <base-alert v-if="reset_message.length > 0" type="danger">
              {{reset_message}}
            </base-alert>
            <base-input
                        v-model="new_password"
                        placeholder="New Password"
                        type="password"
                        addon-left-icon="tim-icons icon-lock-circle"
            >
            </base-input>
          </div>

          <div slot="footer">
            <base-button v-on:click="createPassword()" type="primary" class="mb-3" size="lg" block>
              Set Password
            </base-button>
            <div class="pull-left">
              <h6>
                <a class="link footer-link text-primary" href="#" v-on:click="to_login()">
                  Back to Login
                </a>
              </h6>
            </div>
          </div>
        </card>
      </div>
      <form v-if="on_login" @submit.prevent="login">
        <card class="card-login card-white">
          <div class="row text-center justify-content-center">
            <div class="col-lg-6">
              <img class="mb-4" src="img/pandas/search.png" alt="" />

            </div>
          </div>
          <div>
            <base-alert v-if="error.length > 0" class="red">
              <i class="fas fa-exclamation-triangle fa-2x"></i>
               {{error}}
            </base-alert>
            <base-input
              v-validate="'required|email'"
              name="email"
              :error="getError('email')"
              v-model="email"
              placeholder="Email"
              addon-left-icon="tim-icons icon-email-85"
            >
            </base-input>

            <base-input
              v-validate="'required'"
              name="password"
              :error="getError('password')"
              v-model="password"
              type="password"
              placeholder="Password"
              addon-left-icon="tim-icons icon-lock-circle"
            >
            </base-input>
          </div>

          <div slot="footer">
            <base-button native-type="submit" type="primary" class="mb-3" size="lg" block>
              Sign In
            </base-button>
            <div class="pull-left">
              <h6>
                <a class="link footer-link text-primary" href="#" v-on:click="to_reset()">
                  Forgot Password
                </a>
              </h6>
            </div>
          </div>
        </card>
      </form>
    </div>
  </div>
</template>
<script>
  import { Auth } from 'aws-amplify'
  import { AmplifyEventBus } from 'aws-amplify-vue'
  import { API_LOCATION } from '../../api-config'
  const axios = require('axios')
  import {BaseAlert} from 'src/components'
  import { Loading } from 'element-ui';
  import swal from 'sweetalert2';
  import {date} from '../../plugins/dateFormatter'


  export default {
    data() {
      return {
        email: '',
        password: '',
        subscribe: true,
        error: '',
        loading: false,
        email_reset: '',
        code: '',
        new_password: '',
        on_login: true,
        on_password_reset: false,
        on_password_create: false,
        awaiting_code: false,
        reset_message: '',
        user: ''

      }
    },
    components: {
      BaseAlert,
      Loading
    },
    methods: {
      getAuthToken() {
        return new Promise ( function(resolve) {
          Auth.currentSession()
            .then(data =>{
              resolve(data.idToken.jwtToken)
            })
            .catch(err => console.log(err))
        })
      },
      getError(fieldName) {
        return this.errors.first(fieldName);
      },
      async getUserProfile(){
        var token = await this.getAuthToken()
        let config = { headers: { Authorization: token } }
        let url = API_LOCATION + 'profile'

        axios.get(url, config)
          .then(response => {
            let user = response.data[0]
            if(user.birthday != null){
              user.birthday = date(user.birthday)
            }
            localStorage.setItem('user', JSON.stringify(user))
            if(localStorage.getItem('redirect')) {
              this.$router.push(localStorage.getItem('redirect'))
            } else {
              this.$router.push('/settings/profile')
            }

          })
      },
      async login() {
        let isValidForm = await this.$validator.validateAll();
        if (isValidForm) {
          try {
            this.loading = true
            const user = await Auth.signIn(this.email, this.password)
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
              console.log(user)
              this.user = user
              this.loading = false
              this.to_set_password()

            } else if (user){
              AmplifyEventBus.$emit('authState', 'signedIn')
              this.$sidebar.toggleMinimize();
              this.getUserProfile()
              this.loading = false

            }
          } catch (err) {
            this.loading = false
            this.error = 'Incorrect username or password!'
          }
        }
      },
      to_login() {
        this.on_password_reset = false
        this.on_login = true
        this.on_password_create = false
      },
      to_reset() {
        this.on_password_reset = true
        this.on_login = false
        this.on_password_create = false
      },
      to_set_password() {
        this.on_password_create = true
        this.on_login = false
        this.on_password_reset = false
      },
      async createPassword() {
        this.loading = true
        try {
          await Auth.completeNewPassword(this.user, this.new_password)
          this.loading = false
          swal({
            title: `Password successfully created. Please login!`,
            buttonsStyling: false,
            type: 'success',
            confirmButtonClass: 'btn btn-success btn-fill'
          });
          this.awaiting_code = false
          this.on_password_create = false
          this.on_login = true
          this.code = ''
          this.new_password = ''
          this.email_reset = ''
        } catch (err) {
          if(err.code === 'InvalidPasswordException'){
            this.reset_message = 'Password does not conform to policy: Password not long enough'
          }
          this.loading = false
        }
      },
      async reset() {
        this.loading = true
        try {
          await Auth.forgotPasswordSubmit(this.email_reset, this.code, this.new_password)
          this.loading = false
          swal({
            title: `Password successfully reset. Please login!`,
            buttonsStyling: false,
            type: 'success',
            confirmButtonClass: 'btn btn-success btn-fill'
          });
          this.awaiting_code = false
          this.on_login = true
          this.on_password_reset = false
          this.code = ''
          this.new_password = ''
          this.email_reset = ''
        } catch (err) {
          if(err.code === 'ExpiredCodeException'){
            this.reset_message = 'The code is not valid'
          } else {
            this.reset_message = 'Password too short'
          }
          this.loading = false
        }
      },
      async send_code() {
        this.loading = true
        try {
          await Auth.forgotPassword(this.email_reset)
          this.reset_message = 'If the email is registered, you will receive a verification code.'
          this.loading = false
        } catch (err) {
          if(err.code === 'LimitExceededException'){
            this.reset_message = 'Attempt limit exceeded, please try after some time.'
          } else {
            this.reset_message = 'If the email is registered, you will receive a verification code.'
          }
          this.loading = false
        }
        this.awaiting_code = true
      },
    }
  };
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
  .red{
    background-color: #f03727;
  }
  #loading {
    min-height: 1000px;
    display: flex;
    align-items: center;
  }
  .el-loading-spinner .path {
    stroke: #6aff8d !important;
  }

  .el-loading-mask {
    background: rgba(0, 0, 0, 0.7) !important;
  }
</style>
